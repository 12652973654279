@import "../../../themes/variables.scss";
@import "../../../themes/mixins.scss";

.header-login {
  width: 100%;
  height: 60px;
  @include gradient(right);
  display: flex;

  @media screen and (min-width: 321px) {
    height: 90px;
  }

  .content-brand {
    img {
      margin-top: 25px;
      margin-left: 10px;
      max-width: 185px;

      @media screen and (min-width: 321px) {
        max-width: 240px;
      }

      @media screen and (min-width: 993px) {
        margin-left: 100px;
      }
    }
  }
}
