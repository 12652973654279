// Fonts
$font-family: "MyriadPro";

// Colors
$primary: #0092c4;
$primary-hover: #215a99;
$primary-selecionado: rgba(40, 109, 184, 0.5);
$primary-light: #7daee3;
$danger: #d71820;
$danger-dark: #d71820;
$sucess: #0baa25;
$success-dark: #0baa25;
$sucess-light: lightgreen;
$secondary: #001463;
$theme-color-primary: #0092c4;
$theme-color-secondary: #001463;
$theme-color-third: #2e2f93;
$turn-off-the-light: rgba(0, 0, 0, 0.5);
$turn-off-the-lighter: rgba(0, 0, 0, 0.1);

$white: #fff;
$black: #000;
$black2: #00000029;
$white-half-opacity: rgba(255, 255, 255, 0.8);
$gray: #646464;
$gray-light: #cecece;
$gray-lighter: #fafafa;

$amountOfLight: 10%;

$primary-dark: darken($primary, $amountOfLight);
