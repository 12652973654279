@import '../../../../../../themes/variables.scss';

.wrapper-content-pedido {
  padding: 20px;

  .buttons {
    .flex-row-reverse {
      display: flex;
    }
  }

  button{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }

  button:hover{
    background-color: $secondary;
  }

  .content-pedido-materiais {
    .material {
      .col-lg-4 {
        .lista-materiais {
          box-shadow: 0px 3px 6px $black2;
          border-radius: 31px;
          background-color: white;
          .header-card {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            font-size: 25px;
            font-weight: 600;
            height: 80px;
          }

          .body-card {
            height: 360px;
            font-size: 17px;
            font-weight: 600;
            color: #646464;
            margin: 15px;

            .scrollbar-container {
              height: 80%;
              background-color: transparent;

              .scrollbar {
                border-radius: 10px;
                cursor: pointer;
                z-index: 1;
              }
              .vertical {
                background-color: transparent;
              }
            }

            .scrollbar-track {
              display: none;
            }

            ul {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .lista-materiais .body-card ul label li:hover {
    background-color: $primary;
    color: $white;
    border-radius: 15px;
  }
}

@media only screen and (max-width: 485px) {
  .wrapper-content-pedido {
    .content-pedido-materiais {
      display: flex;
      flex-direction: column;
      align-items: center;

      .material {
        display: flex;
        flex-direction: column;
        width: 100%;

        .col-lg-4 {
          flex: 100%;
          max-width: 100%;

          .lista-materiais {
            box-shadow: 0px 3px 6px $black2;
            border-radius: 51px;
            .header-card {
              border-top-left-radius: 50px;
              border-top-right-radius: 50px;
              font-size: 25px;
              font-weight: 600;
              height: 100px;
            }
          }
        }
      }
    }
  }
}
