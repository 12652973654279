@import '../../../themes/variables.scss';

.table-historico-solicitacoes {
  max-width: 1600px;
  margin: 0 auto;
  padding: 15px;
  border-top: none;

  .table-responsive{
    border-top: none;
  }

  .table{
    border-top: none;
  }

  thead{
    border-top: none;
    tr th{
      border-top: none;
    }
  }

  th {
    font-weight: 900;
    font-size: 20px;
    border-top: none;
  }

  td {
    padding: 0.5rem;
    border-top: none;
  }

  tbody{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-top: none;
  }

  tbody tr:nth-of-type(even) {
    background-color: white;
  }

  .table-button {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 30px;
    min-width: 140px;
    font-size: 20px;
    height: 36px;
    padding-top: 2px;
  }

  .cancelado {
    color: $danger;
  }

  .andamento {
    color: $danger;
  }
}
