// CONFIGURAÇÃO DAS FONTES

// -----------> Myriad Pro Black
@font-face {
  font-family: "MyriadPro";
  font-weight: 900;
  src: url("../assets/fonts/MYRIADPRO-BLACK.OTF") format("opentype");
}
// -----------> Myriad Pro Bold
@font-face {
  font-family: "MyriadPro";
  font-weight: 700;
  src: url("../assets/fonts/MyriadPro-Bold.otf") format("opentype");
}
// -----------> Myriad Pro Semibold
@font-face {
  font-family: "MyriadPro";
  font-weight: 500;
  src: url("../assets/fonts/MyriadPro-Semibold.otf") format("opentype");
}
// Italic
@font-face {
  font-family: "MyriadPro";
  font-weight: 500;
  font-style: italic;
  src: url("../assets/fonts/MyriadPro-SemiboldIt.otf") format("opentype");
}
// -----------> Myriad Pro Light
@font-face {
  font-family: "MyriadPro";
  font-weight: 300;
  src: url("../assets/fonts/MYRIADPRO-LIGHT.OTF") format("opentype");
}
// italic
@font-face {
  font-family: "MyriadPro";
  font-weight: 300;
  font-style: italic;
  src: url("../assets/fonts/MYRIADPRO-LIGHTIT.OTF") format("opentype");
}

$font-family-sans-serif: "MyriadPro";
$font-family-monospace: "MyriadPro";
$font-family-base: "MyriadPro";
