@import '../../themes/variables.scss';

.modal-historic {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-footer {
    text-align: right;

    .button-close {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 30px;
      min-width: 120px;
      font-size: 16px;
    }
  }

  .vertical-timeline.vertical-timeline-custom-line::before {
    background: $gray;
  }

  .vertical-timeline {
    .vertical-timeline-element-icon {
      background-color: $primary;
    }

    .vertical-timeline-element-content {
      border: $gray solid 1px;
    }

    .vertical-timeline-element-content-arrow {
      border-right-color: $gray;
    }

    .vertical-timeline-element-title {
      font-size: 18px;
    }

    .vertical-timeline-element-subtitle {
      font-size: 14px;
      color: $gray;
    }

    .vertical-timeline-element-observation {
      font-size: 12px;
      color: $gray;
    }
  }

  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    left: 130%;
  }

  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content
    .vertical-timeline-element-date,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(2n):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    right: 130%;
  }
}
