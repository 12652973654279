@import '../../themes/variables.scss';

.wrapper-acompanhar-chamados {
  .table-query {
    max-width: 1600px;
    min-height: 494px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 15px;

    th {
      font-weight: 900;
      border-top: none;
      padding: 0.5rem;
    }

    td {
      padding: 0.5rem;
    }

    .table-button {
      border-radius: 30px;
      min-width: 140px;
      font-size: 14px;
      height: 28px;
      padding-top: 2px;
    }

    .table-responsive {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .table-striped {
      box-shadow: 0px 3px 6px $black2;
      border-radius: 5px;
    }

    .voltar {
      margin-top: 20px;

      .btnVoltar {
        flex-direction: row;
        align-self: flex-start;
        background: var(--unnamed-color-286db8) 0% 0% no-repeat padding-box;
        background: $primary 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px $black2;
        border-radius: 30px;
        width: 150px;
        height: 42px;
        padding-top: 8px;

        .icon {
          margin-right: 10px;
        }
      }
    }
  }
}
