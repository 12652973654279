@import '../../themes/variables.scss';

.wrapper {
  z-index: 20;
  background-color: $gray-lighter;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: solid 1px $gray-light;
  width: 90vw;
  max-width: 900px; /* Largura máxima para evitar que o elemento fique muito grande */
  height: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;

  .heade-card {
    width: 90vw;
    height: 10vh;
    max-height: 80px;
    max-width: 900px;
    position: relative;
    display: flex;
    justify-content: center;
    background-color: $primary;
    border-radius: 20px 20px 0 0;
  }

  .bod-card {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 35px;
    padding-right: 35px;
  }

  .bt-card {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 35px;
  }

  .text {
    padding: 15px;
    text-align: justify;
    font-size: 21px;
    font-weight: 600;
  }

  /* Estilos para telas menores */
  @media screen and (max-width: 600px) {
    .wrapper {
      width: 90vw;
      max-width: 100%;
    }
  }
}
