@import '../../themes/variables.scss';

.modal-summary {
  .modal-dialog {
    max-width: 800px;
  }

  .header {
    .close {
      font-size: 30px;
      font-weight: 900;
      color: $primary !important;
      opacity: 1;
    }
  }

  .modal-footer {
    text-align: right;

    .button-close {
      border-radius: 30px;
      min-width: 120px;
      font-size: 16px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
  }
}
