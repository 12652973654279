@import '../../themes/variables.scss';

.modal-solicitar-novamente {
  button {
    border-radius: 30px;
    min-width: 120px;
    font-size: 16px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
}
